<template>
  <span>
    <div v-if="isMoney()" class="money">
      <money-format
        v-for="value in getValues(itemValue)"
        :key="value"
        :value="parseInt(value)"
        :locale="'en'"
        :currency-code="currency"
        :subunits-value="true"
      />
    </div>
    <div v-else-if="isStatus()" class="status">
      <status-badge
        v-for="value in getValues(itemValue)"
        :key="value"
        :variant="value"
      >
        {{ value }}
      </status-badge>
    </div>
    <div v-else-if="isTag()" class="tags">
      <span v-if="itemValue.length">
      <tag v-for="tag in itemValue" :key="tag" :slug="tag" :class="'mr-2'" />
    </span>
    </div>
    <div v-else-if="isDate()" class="date">
      {{ (itemValue * 1000) | formatDate }}
    </div>
    <span v-else class="values">
      <div v-for="(val, index) in getValues(itemValue)" :key="index">
        <div
          v-if="val.length > 30"
          v-tooltip="val"
          >
          {{ stripped(val) }}
        </div>
        <div v-else>
          {{ stripped(val) }}
        </div>
      </div>
    </span>
  </span>
</template>

<script>
import MoneyFormat from "vue-money-format";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import Tag from "@/components/UI/Tag.vue";
export default {
  name: "DataTableElement",
  components: { MoneyFormat, StatusBadge, Tag },
  props: {
    itemValue: {
      type: [String, Number, Array],
      default: "",
      required: true,
    },
    itemKey: {
      type: String,
      default: "",
      required: true,
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    getValues(value) {
      if (typeof value === "string" && value.indexOf(",")) {
        return value.split(",");
      }
      return [value];
    },
    isMoney() {
      return (
        this.itemKey.indexOf("price") !== -1 ||
        this.itemKey.indexOf("total") !== -1
      );
    },
    isStatus() {
      return this.itemKey.indexOf("status") !== -1;
    },
    isTag() {
      return this.itemKey.indexOf("tag") !== -1;
    },
    isDate() {
      return this.itemKey.indexOf("date") !== -1;
    },
    stripped(val) {
      if (val.length > 30) {
        return val.slice(0, 30) + '...';
      }
      return val;
    },
  },
};
</script>

<style scoped>
.values {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
