<template>
  <ApiLoader :is-loading="loading" class="field-list-loader">
    <div class="field-list-wrapper">
      <h3>Fields</h3>
      <p>Switch fields on to add them to the view.</p>
      <div class="field-list">
        <div
          v-for="(field, key) in fieldList"
          :key="key"
          class="mb-1 border-bottom align-middle"
        >
          <div class="field mb-0">
            {{ field.name }}
            <span class="custom-control custom-checkbox float-right">
              <input
                :id="key"
                type="checkbox"
                class="custom-control-input"
                :checked="isChecked(field.key)"
                @click="selectField"
              />
              <label class="custom-control-label" :for="key">&nbsp;</label>
            </span>
          </div>
        </div>
        <p class="mt-4" v-if="fieldType === 'order'">
          Missing fields and data for this view?
          <router-link :to="{ name: 'meta.fields.index' }">
            Enable more data for views
          </router-link>
        </p>
        <hr />
      </div>
    </div>
  </ApiLoader>
</template>

<script>
import jsonAPI from "@/api";
import ApiLoader from "../UI/ApiLoader.vue";
export default {
  name: "",
  props: {
    selectedFields: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    fieldType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      fieldList: [],
      loading: true,
    };
  },
  mounted() {
    if(this.fieldType === 'order') {
      var url = "/availableFields/order";
    } else {
      url = "/availableFields/customer";
    }
    jsonAPI
      .get(url)
      .then((response) => {
        this.fieldList = response.data;
        this.loading = false;
      });
  },
  components: { ApiLoader },
  methods: {
    isChecked(key) {
      if (this.selectedFields) {
        return this.selectedFields[key] ?? false;
      }
      return false;
    },
    selectField(event) {
      const id = event.target.id;
      const val = event.target.checked;
      if (val) {
        console.log("Select: " + id);
        const field = this.fieldList[id];
        console.log(this.fieldList);
        console.log(field);
        this.$emit("add", id, field);
      } else {
        console.log("Deselect: " + id);
        this.$emit("deselect", id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.field-list-loader {
  overflow: hidden;
  height: 100%;
}
.field-list-wrapper {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.field-list {
  overflow: auto;
  height: 100%;
}
.field {
  font-size: 0.9rem;
  line-height: 2rem;
}
.custom-control-input {
  opacity: 0;
  z-index: 1;
}
</style>
