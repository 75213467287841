<template>
  <draggable
    class="mb-4 border"
    @update="onOrderUpdate"
    v-if="Object.keys(activeFields).length > 0"
    v-model="selectedKeys"
  >
    <transition-group class="d-flex">
      <div
        v-for="(item, index) in activeFields"
        :key="'item-' + index"
        class="flex-fill border rounded m-1 p-1 text-center bg-white"
      >
        {{ item.name }}
      </div>
      <div
        key="actions"
        class="flex-fill border rounded m-1 p-1 text-center bg-gray-200"
        @move="
          () => {
            return false;
          }
        "
      >
        Actions
      </div>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "FieldsReorder",
  components: {
    draggable,
  },
  props: {
    activeFields: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedKeys: [],
    };
  },
  mounted() {
    this.selectedKeys = Object.keys(this.activeFields);
  },
  methods: {
    onOrderUpdate() {
      const newOrder = { ...this.activeFields };
      this.selectedKeys.forEach((key, index) => {
        newOrder[key].index = index;
      });
      this.$emit("update", newOrder);
    },
  },
  watch: {
    activeFields(newValue) {
      this.selectedKeys = Object.keys(newValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
