<template>
  <component :is="layout">
    <div class="d-flex">
      <OverviewSidebar
        class="position-fixed p-4 justify-content-between fadeInUp"
        overview="order"
        :hideViews="true"
      >
        <FieldList
          :fieldType="'order'"
          :selected-fields="selected"
          @add="addField"
          @deselect="removeField"
        />
      </OverviewSidebar>
      <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">
        <ApiLoader :is-loading="loading">
          <div class="card border-0 shadow-sm mb-4 w-100">
            <div class="card-header">
              <div class="form-row">
              <div
                class="form-group col-md-12 col-12 required mb-3 p-0"
                :class="{ 'is-invalid': nameError }"
              >
                <input
                  id="name"
                  v-model="view.data.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="View name"
                  autocomplete="off"
                />
              </div>
              <div
                class="form-group col-md-6 col-12 required"
                :class="{ 'is-invalid': errors.has('access') }"
              >
                <label for="access" class="control-label text-capitalize"
                  >Access level:</label
                >
                <select
                  id="access"
                  v-model="access"
                  v-validate="{ required: true }"
                  name="role"
                  class="form-control"
                >
                  <option
                    v-for="opt in accessOptions()"
                    :value="opt.val"
                    :key="opt.val"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <span v-show="errors.has('access')" class="invalid-feedback">{{
                  errors.first("access")
                }}</span>
              </div>
              <div
                v-if="access === 'role'"
                class="form-group col-md-6 col-12 required"
                :class="{ 'is-invalid': errors.has('role') }"
              >
                <label for="role" class="control-label text-capitalize"
                  >Role:</label
                >
                <select
                  id="role"
                  v-model="role"
                  v-validate="{ required: true }"
                  name="role"
                  class="form-control"
                >
                  <option
                    v-for="avlRole in roles"
                    :value="avlRole.value"
                    :key="avlRole.value"
                  >
                    {{ avlRole.text }}
                  </option>
                </select>
                <span v-show="errors.has('role')" class="invalid-feedback">{{
                  errors.first("role")
                }}</span>
              </div>
            </div>
            </div>
            <div v-if="loading" class="card-body text-center mb-12 mt-12">
              <span class="spinner-border" role="status" aria-hidden="true" />
            </div>

            <div
              v-if="orders.data"
              class="card-body"
              data-title="Sorting is available on most columns. Sort all orders filtered across pages."
              data-intro=""
              data-step="7"
              data-position="left"
            >
              <p v-if="fieldKeys.length">
                Drag and drop these elements to rearrange the order of the
                fields in the view
              </p>
              <transition name="slide-fade">
                <FieldsReorder
                  :activeFields="selected"
                  @update="reorderFields"
                />
              </transition>
              <data-table
                :headers="headers()"
                :sort-url="'/order/view/' + view.id"
                :data="orders.data"
              >
                <template
                  v-for="elem in selected"
                  :slot="elem.key"
                  slot-scope="item"
                >
                  <DataTableElement
                    :key="elem.key"
                    v-if="getValue(item.item._source, elem.key)"
                    :item-key="elem.key"
                    :item-value="getValue(item.item._source, elem.key)"
                    :currency="item.item._source.currency"
                  />
                </template>
                <template #actions="{ item }">
                  <router-link
                    :to="{ name: 'order.single', params: { id: item._id } }"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View order
                  </router-link>
                </template>
              </data-table>
            </div>
            <div class="card-footer">
              <ButtonLoading
                :loading="loading"
                :title="'Save view'"
                :class="'btn btn-primary btn-block save-button'"
                v-on:click.native="saveView"
              />
            </div>
          </div>
        </ApiLoader>
      </div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import DataTableElement from "@/components/DataTable/DataTableElement.vue";
import { postToApi } from "@/helpers/apiConnection";
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import ConditionBuilder from "@/components/Automation/ConditionBuilder.vue";
import ApiLoader from "@/components/UI/ApiLoader.vue";
import FieldList from "@/components/Order/FieldList.vue";
import FieldsReorder from "@/components/Order/FieldsReorder.vue";
import { getValues } from "@/helpers/utilities";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import Permission from "@/utils/permission";
import utilities from "@/helpers/utilities";
import { mapGetters } from "vuex";

export default {
  name: "OrderViewCreate",
  components: {
    ApiLoader,
    DataTable,
    DataTableElement,
    Default,
    OverviewSidebar,
    ConditionBuilder,
    FieldList,
    FieldsReorder,
    ButtonLoading,
  },
  extends: Permission,
  data() {
    return {
      layout: "Default",
      orders: {
        data: [],
        links: {},
        total: 0,
      },
      view: {
        id: null,
        group: "view",
        data: {
          name: "",
          view_type: "order",
          view: {},
        },
      },
      access: "organization",
      role: "contributor",
      roles: [
        { text: "Contributor", value: "contributor" },
        { text: "Admin", value: "admin" },
      ],
      selected: {},
      fieldKeys: [],
      page: 1,
      loading: false,
      nameError: false,
    };
  },
  created() {
    if (this.view) {
      this.setupConfig();
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    accessOptions() {
      const opts = [{ val: "personal", name: "Personal" }];
      const canCreateRoleView = this.hasPermission('create-role-view');
      if (canCreateRoleView) {
        opts.push({ val: "role", name: "Role" });
      }
      const canCreateOrgView = this.hasPermission('create-organization-view');
      if (canCreateOrgView) {
        opts.push({ val: "organization", name: "Organization" });
      }
      return opts;
    },
    setupConfig() {
      this.sortFields(this.view.data.view);
    },
    sortFields(viewData) {
      let fields = Object.values(Object.assign({}, viewData));
      fields = fields.sort((a, b) => (a.index > b.index ? 1 : -1));
      this.selected = {};
      fields.forEach((item) => {
        this.selected[item.key] = item;
      });
      this.fieldKeys = Object.keys(this.selected);
    },
    headers() {
      const header = [];
      this.fieldKeys.forEach((key) => {
        const item = this.selected[key];
        header.push({
          title: item.name,
          key: item.key,
          sortable: false,
          sortKey: item.key,
        });
      });
      header.push({
        title: "Actions",
        key: "actions",
      });
      return header;
    },
    getValues,
    async addField(fieldKey, field) {
      const view = { ...this.view };
      field.index = view.data.view.length;
      view.data.view[fieldKey] = field;
      await this.setupConfig();
    },
    async removeField(fieldKey) {
      const view = { ...this.view };
      delete view.data.view[fieldKey];
      await this.setupConfig();
    },
    async reorderFields(fields) {
      this.sortFields(fields);
      const view = { ...this.view };
      view.data.view = fields;
      await this.setupConfig();
    },
    async saveView() {
      if (!this.view.data.name) {
        this.nameError = true;
        return;
      }
      if(Object.keys(this.view.data.view).length === 0) {
        window.Bus.$emit("flash-message", {
          text: "Please select view fields",
          type: "error",
        });
        return;
      }
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.view = utilities.cleanData(this.view);
          switch (this.access) {
            case "personal":
              this.view.owner_id = this.getUser.id;
              break;
            case "role":
              this.view.role = this.role;
              break;
          }
          const result = await postToApi(
            "/configurationData",
            this.view,
            "View saved"
          ).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
          });
          if (result) {
            this.$router.push({
              name: "order.view",
              params: { id: result.id },
            });
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
